import React, {Component} from 'react';
import {Link, NavLink} from 'react-router-dom';
import Sticky from 'react-stickynode';

class CustomNavbar extends Component {
    render() {
        var {mClass, nClass, cClass, slogo, hbtnClass} =this.props;
        return (
            <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
                <header className="header_area">
                <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
                    <div className={`container ${cClass}`}>
                        <a className={`navbar-brand ${slogo}`} to="/">
                            {/* <h1 style={{color:"white", fontWeight:"bold"}}>AhunCash</h1> */}
                            {/* {/* <img src={require("../img/logo2.png")} alt=""/> */}
                            <img src="assets/img/AhunCash_Logo_Project-16.png" style={{height:"50px"}} alt="" />
                            <img src="assets/img/AhunCash_Logo_Project-14.png" style={{height:"50px"}} alt="" />
                           
                        </a>
                        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="menu_toggle">
                                <span className="hamburger">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                                <span className="hamburger-cross">
                                    <span></span>
                                    <span></span>
                                </span>
                            </span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className={`navbar-nav menu ml-auto ${nClass}`}>
                               <li className="nav-item"><a  className="nav-link" href="/">Home</a></li>
                               <li className="nav-item"><a  className="nav-link" href="#about">About AhunCash</a></li>
                               <li className="nav-item"><a  className="nav-link" href="#features">Features</a></li>
                               <li className="nav-item"><a  className="nav-link" href="#services">Services</a></li>
                               <li className="nav-item"><a  className="nav-link" href="#contact">Contact</a></li>
                            </ul>
                            {/* <a className={`btn_get btn_hover ${hbtnClass}`} href="#get-app">Get Started</a> */}
                        </div>
                    </div>
                </nav>
                </header>
            </Sticky>
        );
    }
}

export default CustomNavbar;