import React from "react";
const Banner = () => {
  return (
    <section className="get_started_area">
      <div className="shap one"></div>
      <div className="shap two"></div>
      <div className="shap one three"></div>
      <div className="shap two four"></div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 d-flex align-items-center">
            <div className="get_content">
              <h3 className="f_400 f_p wow fadeInLeft" data-wow-delay="0.2s">
                <span className="telebirr_title">
                  Ready to take your finances to the next level?
                </span>{" "}
              </h3>
              <h2
                className="f_700 f_p f_size_40 l_height50 mb_20 wow fadeInLeft"
                data-wow-delay="0.3s"
              >
                Download the AhunCash app
              </h2>
              {/* <p
                className="f_400 f_p mb_40 wow fadeInLeft"
                data-wow-delay="0.4s"
              >
                Welcome to Ethio Telebirr. One of the few things that anyone can
                say with much conviction these days is the simple fact that
                technology is changing the world.!
              </p> */}
              <a
                href=".#"
                className="app_btn app_btn_one wow fadeInLeft"
                data-wow-delay="0.5s"
              >
                Play Store
              </a>
              <a
                href=".#"
                className="app_btn app_btn_two wow fadeInLeft"
                data-wow-delay="0.6s"
                style={{ color: "white" }}
              >
                <i className="fab fa-apple"></i>App Store
              </a>
            </div>
          </div>
          <div className="col-lg-6 text-right">
            {/* <img src="assets/images/iPhone 12 Graphite Pro Top View Mockup.png" alt="appmyil mobile" style={{maxHeight:"500px"}}/> */}
            <img
              src="assets/img/iPhone 13 Pro Mockup Right View (1).png"
              alt="appmyil mobile"
              style={{ maxHeight: "500px" }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default Banner;
