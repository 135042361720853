import React, {Component} from 'react';

class AppFeatures2 extends Component {
    render(){
        let{image, rClass, Title, description,isFirst}= this.props;
        return(
            <div className={`row h_analytices_features_item align-items-center ${rClass}`}>
                <div className="col-lg-6">
                    <div className="h_security_img text-center">
                        {/* <img className="wow fadeInUp" src="assets/images/iPhone 12 Graphite Pro Top View Mockup.png" style={{maxHeight:"600px"}} alt=""/> */}
                        {isFirst? <img class="tm-about-mobilethumb" src="assets/img/ethiopia-zainab-photo3.jpg" alt="about mobile"/>: <img class="tm-about-mobilethumb" src="assets/img/African american woman grocery shopping.jpg" alt="about mobile"/>}
                   

                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="h_analytices_content">
                        <h2 className="wow fadeInUp" data-wow-delay="0.1s">{Title}</h2>
                        <p className="wow fadeInUp" data-wow-delay="0.3s">{description}</p>
                        {isFirst?(
                        <ul className="list-unstyled">
                            <li className="wow fadeInUp" data-wow-delay="0.5s"><span>Loan limit Ranging from ETB 5,000 - 500,000</span> </li>
                            <li className="wow fadeInUp" data-wow-delay="0.7s"><span>Loan duration is from 1 month to 12 months</span></li>
                            <li className="wow fadeInUp" data-wow-delay="0.9s"><span>Facility Fee of 2% of is calculated on approved loan amount</span> </li>
                            <li className="wow fadeInUp" data-wow-delay="0.9s"><span>Interest ranges from 2%- 3% on monthly basis</span> </li>
                        </ul>

                        ):(
                            <ul className="list-unstyled">
                            <li className="wow fadeInUp" data-wow-delay="0.5s"><span>Pay your bills instantly</span></li>
                            <li className="wow fadeInUp" data-wow-delay="0.7s"><span>Get the item you need to make your life easier</span></li>
                            <li className="wow fadeInUp" data-wow-delay="0.9s"><span>Access micro loans up to 200,000 birr </span> </li>
                            <li className="wow fadeInUp" data-wow-delay="0.9s"><span>Withdraw cash at any branch or through mobile money </span> </li>
                        </ul>
                        )}
                    </div>
                </div>
            </div>
            
        )
    }
}

export default AppFeatures2;