import React from "react";

const Features = () => {
  return (
    <div
      id="about"
      class="tm-features-area tm-section tm-padding-section bg-white"
    >
      <div class="container">
        <div class="row justify-content-center mt-30-reverse">
          <div class="col-lg-4 col-md-6 col-12 mt-30">
            <div class="tm-feature">
              <span class="tm-feature-icon">
                <i class="flaticon-keyword"></i>
              </span>
              <div class="tm-feature-content">
                <h4>Fast & Simple Application</h4>
                <p>
                  Initiate a loan application from any location and at any time.
                  No collateral and no guarantee.
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-12 mt-30">
            <div class="tm-feature">
              <span class="tm-feature-icon">
                <i class="flaticon-document"></i>
              </span>
              <div class="tm-feature-content">
                <h4>We Say ‘Yes’ More</h4>
                <p>
                  Using the latest technology and banks better quality lending
                  decisions through simplified processes.
                </p>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6 col-12 mt-30">
            <div class="tm-feature">
              <span class="tm-feature-icon">
                <i class="flaticon-cover"></i>
              </span>
              <div class="tm-feature-content">
                <h4>Collateral Free Loan</h4>
                <p>
                  No collateral. Unlike the conventional lending system, the
                  credit worthiness of the MSME is solely decided.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
