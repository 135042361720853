import React from "react";

const AboutUs = () => {
  return (
    <div
      id="tm-area-about"
      class="tm-about-area tm-section tm-padding-section bg-grey"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="tm-sectiontitle text-center">
              <h2>About AhunCash</h2>
              <span class="tm-sectiontitle-divider">
                <i class="zmdi zmdi-fullscreen"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="row align-items-center row-reverse">
          <div class="col-lg-6">
            <div class="tm-about-image">
              <img
                class="tm-about-mobilethumb"
                src="assets/img/young_african_woman_selling_local_market_holding_mobile_point_sale.jpg"
                alt="about mobile"
              />
            </div>
          </div>
          <div class="col-lg-6">
            <div class="tm-about-content">
              <h4>Why AhunCash?</h4>
              <h6>
                Ethiopian financial institutions lack scalable and digitized
                methods to assess the creditworthiness of MSMEs without a
                guarantee. This is mainly due to a lack of collateral,
                technology, and access to the digital economy, preventing MSMEs
                from getting loans from banks.
              </h6>
              <p>
                AhunCash enables banks to fulfill financial needs of individuals
                and MSMEs: .{" "}
              </p>
              <ul class="stylish-list">
                <li>
                  <i class="zmdi zmdi-check"></i> Without the need for
                  collateral.
                </li>
                <li>
                  <i class="zmdi zmdi-check"></i> Allowing customers to become
                  more efficient and accomplish more with less.
                </li>
                <li>
                  <i class="zmdi zmdi-check"></i> Cutting the wait time from
                  weeks to minutes.
                </li>
              </ul>
              <a href="#" class="tm-button">
                <span style={{ color: "white" }}>Read More</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
