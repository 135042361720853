import React from "react";

const AdvancedFeatures = () => {
  return (
    <div
      id="features"
      class="tm-services-area tm-section tm-padding-section bg-white"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            <div class="tm-sectiontitle text-center">
              <h2>Loan Application Process</h2>
              <span class="tm-sectiontitle-divider">
                <i class="zmdi zmdi-fullscreen"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="row mt-40-reverse">
              <div class="col-lg-12 col-md-6 col-12 mt-40">
                <div class="tm-service">
                  <span class="tm-service-icon">
                    <i class="flaticon-friendship"></i>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ width: "70px", height: "72px" }}
                    >
                      <path
                        fill-rule="evenodd"
                        d="M52.556,54.307 C64.348,37.302 73.747,12.982 68.512,4.877 C60.795,-7.064 13.848,4.659 2.850,20.520 C2.466,21.074 2.124,21.636 1.831,22.199 C-6.854,38.889 17.104,75.968 33.774,71.638 C39.513,70.148 46.364,63.237 52.556,54.307 "
                      />
                    </svg>
                  </span>
                  <div class="tm-service-content">
                    <h4>Download the app</h4>
                    <p>Find the app on Play Store or App store.</p>
                  </div>
                </div>
              </div>

              <div class="col-lg-12 col-md-6 col-12 mt-40">
                <div class="tm-service">
                  <span class="tm-service-icon">
                    <i class="flaticon-support"></i>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ width: "70px", height: "72px" }}
                    >
                      <path
                        fill-rule="evenodd"
                        d="M52.556,54.307 C64.348,37.302 73.747,12.982 68.512,4.877 C60.795,-7.064 13.848,4.659 2.850,20.520 C2.466,21.074 2.124,21.636 1.831,22.199 C-6.854,38.889 17.104,75.968 33.774,71.638 C39.513,70.148 46.364,63.237 52.556,54.307 "
                      />
                    </svg>
                  </span>
                  <div class="tm-service-content">
                    <h4>Register for loan</h4>
                    <p>
                      Register your company and personal information after
                      downloading the App.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-12 col-md-6 col-12 mt-40">
                <div class="tm-service">
                  <span class="tm-service-icon">
                    <i class="flaticon-team"></i>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ width: "70px", height: "72px" }}
                    >
                      <path
                        fill-rule="evenodd"
                        d="M52.556,54.307 C64.348,37.302 73.747,12.982 68.512,4.877 C60.795,-7.064 13.848,4.659 2.850,20.520 C2.466,21.074 2.124,21.636 1.831,22.199 C-6.854,38.889 17.104,75.968 33.774,71.638 C39.513,70.148 46.364,63.237 52.556,54.307 "
                      />
                    </svg>
                  </span>
                  <div class="tm-service-content">
                    <h4>Loan Request</h4>
                    <p>
                      Once you have registered on the App, you will be able to
                      request a loan.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-12 col-md-6 col-12 mt-40">
                <div class="tm-service">
                  <span class="tm-service-icon">
                    <i class="flaticon-hourglass"></i>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ width: "70px", height: "72px" }}
                    >
                      <path
                        fill-rule="evenodd"
                        d="M52.556,54.307 C64.348,37.302 73.747,12.982 68.512,4.877 C60.795,-7.064 13.848,4.659 2.850,20.520 C2.466,21.074 2.124,21.636 1.831,22.199 C-6.854,38.889 17.104,75.968 33.774,71.638 C39.513,70.148 46.364,63.237 52.556,54.307 "
                      />
                    </svg>
                  </span>
                  <div class="tm-service-content">
                    <h4>Request Approval</h4>
                    <p>
                      The concerned body will check and approve your loan
                      request once you send it.
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 col-md-6 col-12 mt-40">
                <div class="tm-service">
                  <span class="tm-service-icon">
                    <i class="flaticon-hourglass"></i>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ width: "70px", height: "72px" }}
                    >
                      <path
                        fill-rule="evenodd"
                        d="M52.556,54.307 C64.348,37.302 73.747,12.982 68.512,4.877 C60.795,-7.064 13.848,4.659 2.850,20.520 C2.466,21.074 2.124,21.636 1.831,22.199 C-6.854,38.889 17.104,75.968 33.774,71.638 C39.513,70.148 46.364,63.237 52.556,54.307 "
                      />
                    </svg>
                  </span>
                  <div class="tm-service-content">
                    <h4>Pay or Cash collection</h4>
                    <p>
                      Finally, you can pay for services/goods or collect the
                      money from a bank branch or via mobile banking.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="tm-service-image">
              <div class="tm-service-image-1 is-visible">
                {/* <img src="assets/images/service-image-1.png" alt="appmyil mobile"/> */}
                {/* <img src="assets/images/iPhone 12 Graphite Pro Top View Mockup.png" alt="appmyil mobile"/> */}
                <img
                  src="assets/images/iPhone 14 Pro Space Black Mockup (1).png"
                  alt="appmyil mobile"
                />
                {/* <img src="assets/images/iPhone 14 Pro Space Black Mockup.png" alt="appmyil mobile"/> */}
              </div>
              <div class="tm-service-image-2">
                {/* <img src="assets/images/heroslider-overlay-shape.png" alt="heroslider ovelay" class="tm-heroslider-ovelayshape"/> */}
                <img
                  src="assets/images/iPhone 14 Pro Space Black Mockup (1).png"
                  alt="appmyil mobile"
                />
                {/* <img src="assets/images/service-image-2.png" alt="appmyil mobile"/> */}
              </div>
              <div class="tm-service-mobileshowanim">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvancedFeatures;
