import React from 'react'

const DownloadStatus = () => {
  return (
    <div id="tm-area-download" class="tm-downlaod-area tm-section tm-padding-section bg-gradient">
                <div class="tm-download-bgshape">
                    <img src="assets/images/download-bgshape.png" alt="bg shape"/>

                </div>
                <div class="container text-center">
                    {/* <div class="row align-items-center "> */}
                        {/* <div class="col-10"> */}
                            <div class="tm-download-content">
                                {/* <img src="assets/images/logo-white.png" alt="appmyil"/> */}
                            <img src="assets/img/AhunCash_Logo_Project-15.png" alt="appmyil logo" style={{maxWidth:"400px"}}/>
                                    <br />
                                {/* <h3>Website Made By ESAM Technologies</h3> */}
                                {/* <h4>Contact Us</h4> */}
                                <div class="tm-buttongroup">
                                    <a href="https://www.linkedin.com/in/esmael-mohammed1/" class="tm-button tm-button-lg tm-button-white"><i class="zmdi zmdi-linkedin"></i><span>Linkedin</span></a>
                                    <a href="tel:+251973126954"class="tm-button tm-button-lg tm-button-white tm-button-transparent"><i
                                            class="zmdi zmdi-phone"></i><span>Phone</span></a>
                                </div>
                            </div>
                        {/* </div> */}
                        {/* <div class="col-lg-6">
                            <div class="tm-download-graph text-center">
                                <h5>Download Statics (Last 6 month)</h5>
                                <canvas id="downloadgraph" height="185"></canvas>
                            </div>
                        </div> */}
                    {/* </div> */}
                </div>
            </div>
  )
}

export default DownloadStatus