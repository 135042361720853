import React from "react";

const Header = () => {
  return (
    <div id="wrapper" class="wrapper">
      {/* <!-- Header Area --> */}
      {/* <div class="tm-header tm-header-fixed tm-sticky-header">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-3 col-md-9 col-9">
              <a class="tm-header-logo" href="index.html">
                <img
                  src="assets/images/esam logo yelow.png"
                  alt="appmyil logo"
                />
              </a>
            </div>
            <div class="col-lg-9 col-md-3 col-3">
              <nav class="tm-navigation tm-header-navigation">
                <ul>
                  <li class="current">
                    <a href="#tm-area-heroslider">Home</a>
                  </li>
                  <li>
                    <a href="#tm-area-features">About</a>
                  </li>
                  <li>
                    <a href="#tm-area-screenshots">Feature</a>
                  </li>
                  <li>
                    <a href="#tm-area-team">Service</a>
                  </li>
                  <li>
                    <a href="#tm-area-pricing">Price</a>
                  </li>
                  <li class="tm-navigation-dropdown">
                    <a href="#tm-area-blog">Blog</a>
                    <ul>
                      <li>
                        <a href="blog.html">Blog Grid</a>
                      </li>
                      <li>
                        <a href="blog-without-sidebar.html">
                          Blog Grid Without Sidebar
                        </a>
                      </li>
                      <li>
                        <a href="blog-details.html">Blog Details</a>
                      </li>
                      <li>
                        <a href="blog-details-without-sidebar.html">
                          Blog Details Without Sidebar
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#tm-area-contact">Contact</a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          <div class="tm-mobilenav"></div>
        </div>
      </div> */}
      {/* <!--// Header Area --> */}

      {/* <!-- Heroslider Area --> */}

      <div id="tm-area-heroslider" class="tm-heroslider">
        <div class="tm-heroslider-inner">
          <img
            src="assets/images/heroslider-overlay-shape.png"
            alt="heroslider ovelay"
            class="tm-heroslider-ovelayshape"
          />
          <div class="container">
            <div class="row align-items-center">
              <div class="col-xl-8 col-lg-7 order-2 order-lg-1">
                <div class="tm-heroslider-content">
                  <h1>
                    Powering Access to Digital <br />{" "}
                    <span className=""> Financial Possibilities</span>
                    {/* <br /> <span className="telebirr_title"> ToloCash</span> */}
                  </h1>
                  <p>
                    AhunCash serves as the entry point to financial inclusion
                    for the MSMEs and individuals in Ethiopia by offering
                    instant, small, short-term, productive credit from banks.{" "}
                  </p>
                  <div class="tm-buttongroup">
                    <a
                      href="#"
                      class="tm-button tm-button-lg tm-button-white tm-button-transparent"
                    >
                      <i class="zmdi zmdi-android"></i>
                      <span>Google Play</span>
                    </a>
                    <a
                      href="#"
                      class="tm-button tm-button-lg tm-button-white tm-button-transparent"
                    >
                      <i class="zmdi zmdi-apple"></i>
                      <span>IOS App Store</span>
                    </a>
                  </div>
                  <a href="#tm-area-features" class="tm-heroslider-scrolldown">
                    <i class="zmdi zmdi-square-down"></i>
                  </a>
                </div>
              </div>
              <div class="col-xl-4 col-lg-5 order-1 order-lg-2">
                <div class="tm-heroslider-mobileshow">
                  {/* <!-- <img src="assets/images/phone mockup.png" alt="appmyil mobile"> --> */}
                  <img
                    // src="assets/images/iPhone 12 Graphite Pro Top View Mockup.png"
                    src="assets/images/iPhone 12 Graphite Pro Top View Mockup (1).png"
                    alt="appmyil mobile"
                  />
                  {/* <!-- <img src="assets/images/heroslider-mobile-show.png" alt="appmyil mobile"> --> */}
                  <div class="tm-heroslider-mobileshowanim">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
