import React, { Component } from "react";

class AppFeatures extends Component {
  render() {
    let {
    //   img1,
    //   img2,
    //   img3,
      rowClass,
      col1,
      col2,
      url,
      titleSmall,
      titlebig,
      p,
      icon,
      pClass
    } = this.props;
    return (
      <section className="chat_features_area">
        <div className="container">
          <div className={`row align-items-center ${rowClass}`}>
            <div className={col1}>
              <div className="chat_features_img chat_features_img_one">
                {/* <img
                  className="p_absoulte dot_bg"
                  style={{maxHeight:'500px'}}
                  src="assets/images/iPhone 12 Graphite Pro Top View Mockup.png"
                  alt=""
                /> */}
                <img
                  className="chat_one"
                  data-parallax='{"x": 0, "y": 80}'
                  
                  src="assets/images/iPhone 12 Graphite Pro Top View Mockup.png"
                  alt=""
                  style={{maxHeight:'500px'}}
                />
                {/* <img
                  className="p_absoulte chat_two"
                  data-parallax='{"x": 0, "y": -80}'
                  style={{maxHeight:'500px'}}
                  src="assets/images/iPhone 12 Graphite Pro Top View Mockup.png"
                  alt=""
                /> */}
              </div>
            </div>
            <div className={col2}>
              <div className={`chat_features_content ${pClass}`} >
                {/* <img 
                src="assets/images/iPhone 12 Graphite Pro Top View Mockup.png"/> */}
                <h2>
                  <span>{titleSmall}</span> {titlebig}
                </h2>
                <p>{p}</p>
                <a href={url}>Learn more</a>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default AppFeatures;
