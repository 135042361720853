import React from "react";

const Footer = () => {
  return (
    <div class="tm-footer bg-gradient">
      <div class="tm-footer-bgshape">
        <img
          src="assets/images/footer-bgshape.png"
          alt="footer background shape"
        />
      </div>
      <div class="container">
        <div class="tm-footer-toparea tm-padding-section">
          <div class="row widgets tm-footer-widgets">
            <div class="col-xl-5 col-lg-4 col-md-6 col-12">
              <div class="single-widget widget-info">
                {/* <h1 style={{color:"white" , fontWeight:"bold"}}>AhunCash</h1> */}
                <a class="widget-info-logo">
                  <img
                    src="assets/img/AhunCash_Logo_Project-16.png"
                    alt="appmyil logo"
                    style={{ width: "300px" }}
                  />
                </a>
                <p>
                  One of the few things that anyone can say with much conviction
                  these days is the simple fact that technology is changing the
                  world. changes being ushered in by technology is that they are
                  helping to make life easier.
                </p>
              </div>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-6 col-12">
              <div class="single-widget widget-quicklinks">
                <h5 class="widget-title">Services</h5>
                <ul>
                  <li>
                    <a href="#">About</a>
                  </li>
                  <li>
                    <a href="#">Blog</a>
                  </li>
                  <li>
                    <a href="#">FAQs</a>
                  </li>
                  <li>
                    <a href="#">Contact</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-6 col-12">
              <div class="single-widget widget-quicklinks">
                <h5 class="widget-title">Support</h5>
                <ul>
                  <li>
                    <a href="#">Help center</a>
                  </li>
                  <li>
                    <a href="#">How it work</a>
                  </li>
                  <li>
                    <a href="#">Terms of use</a>
                  </li>
                  <li>
                    <a href="#">Forum</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-6 col-12">
              <div class="single-widget widget-recentpost">
                <h5 class="widget-title">Recent Posts</h5>
                <ul>
                  <li>
                    <div class="widget-recentpost-content">
                      <h6>
                        <a href="blog-details.html">AhunCash new services</a>
                      </h6>
                      <span>February 01, 2023</span>
                    </div>
                  </li>
                  <li>
                    <div class="widget-recentpost-content">
                      <h6>
                        <a href="blog-details.html">
                          AhunCash login process Business
                        </a>
                      </h6>
                      <span>February 01, 2023</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="tm-footer-bottomarea">
          <div class="row align-items-center">
            <div class="col-md-7">
              <p class="tm-footer-copyright" style={{ color: "white" }}>
                Copyright 2023 <a href="#">AhunCash Inc</a>
              </p>
            </div>
            <div class="col-md-5">
              <ul class="tm-footer-social">
                <li>
                  <a href="#">
                    <i class="zmdi zmdi-facebook"></i>
                    <i class="zmdi zmdi-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="zmdi zmdi-dribbble"></i>
                    <i class="zmdi zmdi-dribbble"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="zmdi zmdi-behance"></i>
                    <i class="zmdi zmdi-behance"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="zmdi zmdi-twitter"></i>
                    <i class="zmdi zmdi-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="zmdi zmdi-pinterest"></i>
                    <i class="zmdi zmdi-pinterest"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
