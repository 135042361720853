import "./App.css";
import AboutUs from "./components/AboutUs/AboutUs";
import AdvancedFeatures from "./components/AdvancedFeatures/AdvancedFeatures";
import AppFeatures from "./components/AppFeatures/AppFeatures";
import AppFeatures2 from "./components/AppFeatures2/AppFeatures2";
import Banner from "./components/Banner/Banner";
import Contact from "./components/Contact/Contact";
import DownloadStatus from "./components/DownloadStatus/DownloadStatus";
import Features from "./components/Features/Features";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import CustomNavbar from "./components/Navbar/Navbar";
import Testimonials from "./components/Testimonials/Testimonials";
function App() {
  const isFirst = true;
  return (
    <div className="App">
      <CustomNavbar
        mClass="menu_eight"
        nClass="w_menu"
        slogo="sticky_logo"
        hbtnClass="security_btn"
      />
      <Header />
      <main class="page-content">
        <Features />
        <AboutUs />
        <AdvancedFeatures />
      </main>
      <DownloadStatus />
      <section className="h_security_about_area" id="services">
        <div className="container">
          <div class="tm-sectiontitle text-center pt-50">
            <h2>Our Services</h2>
            <span class="tm-sectiontitle-divider">
              <i class="zmdi zmdi-fullscreen"></i>
            </span>
          </div>
          <AppFeatures2
            isFirst = {isFirst}
            image="about_img_1.jpg"
            Title="AhunCash Business Loan"
            description="AhunCash business loan is designed to provide financial support to MSMEs that are already operational. We provide funding to small businesses, to facilitate the running of their day-to-day operations in a seamless way."
          />
          <AppFeatures2
            image="about_img_2.jpg"
            rClass="flex-row-reverse"
            Title="AhunCash Consumer Loan"
            description="Our consumer loan is tailored to meet the needs of government and non-government organizations, as well as individuals. It can be easily accessed from smartphones, making it convenient for users to get loans to pay for items or unexpected expenses.
            "
          />
        </div>
      </section>
      {/* <AppFeatures rowClass='flex-row-reverse' pClass="pr_70" col1="col-lg-6 offset-lg-1" col2="col-lg-5" icon="chat_icon.png" img1="dot.png" img2="chat_02.png" img3="chat_01.png" titleSmall="Move deals" titlebig="through your funnel – fast"
      p="Real-time tools and rich insights mean our support solution amplifies your team for an unbeatable experience." url="#"/>
      <AppFeatures  pClass="pl_70" col1="col-lg-6" col2="col-lg-5 offset-lg-1" icon="video_icon.png" img1="dot.png" img2="skyp_1.png" img3="skyp_2.png" titleSmall="First ever" titlebig="Video Calling Support"
      p="Real-time tools and rich insights mean our support solution amplifies your team for an unbeatable experience." url="#"/>
      <AppFeatures rowClass='flex-row-reverse' pClass="pr_60" col1="col-lg-6 offset-lg-1" col2="col-lg-5" icon="dashboard.png" img1="dot.png" img2="support_dashboard.jpg" img3="skyp_2.png" titleSmall="Dedicated" titlebig="Dashboard Support"
      p="Real-time tools and rich insights mean our support solution amplifies your team for an unbeatable experience." url="#"/> */}
      <br />
      <br />
      <Banner />
      {/* <Testimonials/> */}
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
