import React from "react";

const Contact = () => {
  return (
    <div
      id="contact"
      class="tm-contact-area tm-section tm-padding-section bg-white"
    >
      <div class="tm-contact-bgshape">
        <img src="assets/images/bg-shape-contact.png" alt="contact shape" />
      </div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="tm-sectiontitle text-center">
            <h2>Contact With Us</h2>
            <span class="tm-sectiontitle-divider">
              <i class="zmdi zmdi-fullscreen"></i>
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-8">
            <form
              id="tm-contactform"
              action="assets/php/mailer.php"
              method="post"
              class="tm-form tm-contact-form"
            >
              <div class="tm-form-inner">
                <div class="tm-form-field tm-form-fieldhalf">
                  <input
                    type="text"
                    name="name"
                    placeholder="Full Name"
                    required="required"
                  />
                  <span class="tm-form-animatedline"></span>
                </div>
                <div class="tm-form-field tm-form-fieldhalf">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email Address"
                    required="required"
                  />
                  <span class="tm-form-animatedline"></span>
                </div>
                <div class="tm-form-field tm-form-fieldhalf">
                  <input
                    type="text"
                    name="subject"
                    placeholder="Subject"
                    required="required"
                  />
                  <span class="tm-form-animatedline"></span>
                </div>
                <div class="tm-form-field tm-form-fieldhalf">
                  <input name="phone" type="text" placeholder="Phone" />
                  <span class="tm-form-animatedline"></span>
                </div>
                <div class="tm-form-field">
                  <textarea
                    name="message"
                    cols="30"
                    rows="4"
                    placeholder="Message"
                  ></textarea>
                  <span class="tm-form-animatedline"></span>
                </div>
                <div class="tm-form-field">
                  <button type="submit" class="tm-button">
                    <span>Send Message</span>
                  </button>
                </div>
              </div>
            </form>
            <p class="form-messages"></p>
          </div>
          <div class="col-lg-4">
            <div class="tm-contact-content">
              <div class="tm-contact-block">
                <span class="tm-contact-block-icon">
                  <i class="zmdi zmdi-pin"></i>
                </span>
                <div class="tm-contact-block-content">
                  <h6>Address</h6>
                  <p>Ethiopia, Addis Ababa</p>
                </div>
              </div>
              <div class="tm-contact-block">
                <span class="tm-contact-block-icon">
                  <i class="zmdi zmdi-phone-in-talk"></i>
                </span>
                <div class="tm-contact-block-content">
                  <h6>Phone</h6>
                  <p>
                    <a href="tel:+251920385881">(+251) - 920 385 881</a>
                  </p>
                  <p>
                    <a href="tel:+251920385881">(+251) - 920 385 881</a>
                  </p>
                </div>
              </div>
              <div class="tm-contact-block">
                <span class="tm-contact-block-icon">
                  <i class="zmdi zmdi-email"></i>
                </span>
                <div class="tm-contact-block-content">
                  <h6>Email</h6>
                  <p>
                    <a href="mailto:info@example.com">Contact@ahuncash.com</a>
                  </p>
                  <p>
                    {/* <a href="mailto:info2@example.com">Contact@ahuncash.com</a> */}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
